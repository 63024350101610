import {
  estiloColumns, estiloRows,
  estiloSubtitulos,
  estiloTitulos,
} from '@/utils/reportes/estilosReportes/estilosReportesGenerales'
import { getUserData } from '@/auth/utils'
import { calcularFecha } from '@/utils/fechas'

const usuario = JSON.parse(localStorage.getItem('userData'))
export const tituloPrincipal = {
  titulo: usuario.company.companyStyle.headerReportes,
  style: estiloTitulos,
}
export const tituloSecundario = {
  titulo: 'LUMINARIAS',
  style: estiloTitulos,
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE LUMINARIAS',
  style: estiloSubtitulos,
}

export const tituloDescarga = 'REPORTE GENERAL DE LUMINARIAS'

export function getColumns() {
  const columns = [
    {
      label: 'No', // style que va tener la columna en el reporte
      width: 8,
      style: estiloColumns,
    },
    {
      label: 'Tipo Lámpara',
      width: 40,
      style: estiloColumns,
    },
    {
      label: 'Serie',
      width: 15,
      style: estiloColumns,
    },
    {
      label: 'Tipo',
      width: 15,
      style: estiloColumns,
    },
    {
      label: 'Potencia',
      width: 10,
      style: estiloColumns,
    },
    {
      label: 'Fecha Creación',
      width: 18,
      style: estiloColumns,
    },
    {
      label: 'Estado',
      width: 15,
      style: estiloColumns,
    },
  ]
  if (usuario.company.name === 'APMIX') {
    const columnID = {
      label: 'ID Luminaria',
      width: 30,
      style: estiloColumns,
    }
    columns.push(columnID)
  }
  return columns
}
export async function getRows(rows) {
  const listado = []
  let counter = 1

  rows.forEach(row => {
    const item = {
      no: {
        value: counter,
        type: 'number',
        style: estiloRows, // style que va tener la fila en el reporte
      },
      tipoLampara: {
        value: row.tipoLampara ? row.tipoLampara.nombre : '',
        type: 'string',
        style: estiloRows,
      },
      serie: {
        value: row.serie ? row.serie : '',
        type: 'string',
        style: estiloRows,
      },
      tipo: {
        value: row.tipo ? row.tipo : '',
        type: 'string',
        style: estiloRows,
      },
      potencia: {
        value: row.potencia ? row.potencia.nombre : '',
        type: 'number',
        style: estiloRows,
      },
      fechaCreacion: {
        value: row.fechaCreacion ? calcularFecha(row.fechaCreacion) : '',
        type: 'string',
        style: estiloRows,
      },
      estado: {
        value: row.estado ? row.estado : '',
        type: 'string',
        style: estiloRows,
      },
    }

    if (usuario.company.name === 'APMIX') {
      item.id = {
        value: row.id ? row.id : '',
        type: 'string',
        style: estiloRows,
      }
    }

    listado.push(item)
    // eslint-disable-next-line no-plusplus
    counter++
  })

  return listado
}

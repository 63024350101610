<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          block
          style="margin-top: 23px"
          variant="primary"
          @click="abrirModal"
        >
          {{ tituloBoton() }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalLuminaria"
      title="Formulario de Recepción de Luminaria"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Guardar Recepción"
      size="lg"
      hide-footer
      @hidden="limpiarModal"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="show"
          >
            <b-row>
              <b-col
                v-if="origenEmpresa === 'APMIX'"
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Tipo
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="luminaria.tipo"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-else
                cols="12"
                :sm="origenEmpresa !== 'APMIX' ? 6 : 4"
              >
                <h5 class="text-primary">
                  Clase de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Clase de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.modeloLampara"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                :sm="origenEmpresa !== 'APMIX' ? 6 : 8"
              >
                <h5 class="text-primary">
                  Tipo Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.tipoLampara"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="tiposLampara"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                :sm="origenEmpresa !== 'APMIX' ? 6 : 4"
              >
                <h5 class="text-primary">
                  Serie
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Serie"
                    rules="required"
                  >
                    <b-form-input
                      v-model="luminaria.serie"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                :sm="origenEmpresa !== 'APMIX' ? 6 : 4"
              >
                <h5 class="text-primary">
                  Potencia
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Potencia"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.potencia"
                      :state="errors.length > 0 ? false : null"
                      label="nombre"
                      value="nombre"
                      :options="potencias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="origenEmpresa === 'APMIX'"
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Lote
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Lote"
                    rules="required"
                  >
                    <b-form-input
                      v-model="luminaria.lote"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="origen === 'Manual'"
                cols="12"
              >
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentario"
                      class="qr-textarea"
                      no-resize
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row v-if="origen !== 'test'">
              <b-col cols="7">
                <h6 class="text-primary">
                  Fotografía de Recepción de Luminaria
                </h6>
              </b-col>
              <b-col
                cols="5"
                style="margin-top: -10px"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto</span>
                </b-button>
              </b-col>
              <b-col
                style="justify-content: center; display: flex"
                cols="12"
              >
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosIngresadas"
                      :key="index"
                    >
                      <td style="width: 280px">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenRecepcion(index, $event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Guardar Recepción
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { saveImagen } from '@/utils/imagenes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { findLuminariasLab, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    Imagen,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BCol,
    BRow,
    BForm,
    vSelect,
    BOverlay,
    BFormInput,
    BFormTextarea,
  },
  props: {
    potencias: {
      type: Array,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
    datosExistentesLuminaria: {
      type: Object,
      required: false,
      default: null,
    },
    origenEmpresa: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      required,
      show: false,
      modalLuminaria: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      luminaria: {
        tipo: '',
        tipoLampara: '',
        serie: null,
        potencia: '',
        estado: '',
        lote: '',
        tracking: [],
        imagenes: [],
        seguimiento: [],
        fechaCreacion: new Date(),
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      fotosIngresadas: [''],
      luminariaInicial: '',
      comentario: '',
      modelos: [],
    }
  },
  async created() {
    if (this.origen === 'Detalle') {
      this.luminaria = this.datosExistentesLuminaria
      this.luminariaInicial = this.luminaria
      this.comentario = this.datosExistentesLuminaria.seguimiento[0].comentario
    }
    this.modelos = await getlistado(3, 'Modelos', false, this.usuario)
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarRecepcion()
            this.$bvModal.hide('modal-1')
          }
        })
    },
    async guardarRecepcion() {
      try {
        this.show = true
        if (this.origen === 'Manual') {
          const filter = {
            where: {
              companyId: this.usuario.company.id,
              serie: this.luminaria.serie,
            },
          }
          const serieExistente = await findLuminariasLab(filter)
          if (serieExistente && serieExistente.length > 0) {
            console.log(this.luminaria.serie, 'SERIE')
            console.log(this.luminaria.serie, 'SERIE EXISTENTE')
            mensajeError('Ya existe una luminaria con la misma serie en la base de datos.')
            return
          }
        }
        const datosUsuario = usuario => ({
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: `${usuario.email}`,
          telefono: '',
        })

        this.luminaria.usuarioCreacion = datosUsuario(this.usuario)
        this.luminaria.tracking.push({
          usuario: datosUsuario(this.usuario),
          estado: 'INGRESADA',
          fecha: new Date(),
        })

        if (this.origen === 'Manual') {
          this.luminaria.estado = 'PRUEBAS'
          this.luminaria.seguimiento.push({
            usuario: datosUsuario(this.usuario),
            estado: 'INGRESADA',
            fecha: new Date(),
            comentarios: this.comentario,
            luminariaInicial: {
              tipo: this.luminaria.tipo,
              serie: this.luminaria.serie,
              potencia: this.luminaria.potencia,
              tipoLampara: this.luminaria.tipoLampara,
              estado: this.luminaria.estado,
              modeloLampara: this.luminaria.modeloLampara,
            },
          })
        } else if (this.origen === 'test') {
          this.luminaria.estado = 'INGRESADA'
        }

        if (this.origenEmpresa !== 'APMIX') {
          this.luminaria.lote = ''
          this.luminaria.tipo = this.luminaria.modeloLampara.nombre
        }

        const body = { ...this.luminaria }

        const item = await updateCreateLuminariaLab(body, 2)

        if (!item) {
          mensajeError('Inconvenientes almacenando la recepción de luminaria!, intente nuevamente!')
          this.show = false
          return
        }

        const urlBucket = `luminaria/${item.id}`
        const isMobile = window.screen.width <= 760

        // eslint-disable-next-line no-restricted-syntax
        for (const foto of this.fotosIngresadas) {
          if (foto !== '') {
            // eslint-disable-next-line no-await-in-loop
            const imagen = await saveImagen(urlBucket, this.localization, foto, 'INGRESADA', '', this.luminaria.usuarioCreacion, isMobile)
            if (imagen) {
              item.imagenes.push(imagen)
            }
          }
        }
        await updateCreateLuminariaLab(item, 1)

        mensajeInformativo('Guardada', 'Luminaria Almacenada Correctamente!')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Luminaria Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Luminaria almacenada exitosamente!',
          },
        })
        this.$emit('cerrar-ingreso')
        this.modalLuminaria = false
      } catch (error) {
        console.log(error)
        mensajeError('Se produjo un error al guardar la luminaria.')
      } finally {
        this.show = false
      }
    },
    limpiarModal() {
      if (this.origen === 'Manual') {
        this.luminaria = {
          tipo: '',
          tipoLampara: '',
          serie: null,
          potencia: '',
          estado: '',
          lote: '',
          tracking: [],
          imagenes: [],
          seguimiento: [],
          fechaCreacion: new Date(),
          usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
          companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        }
        this.comentario = null
      }
    },
    // eslint-disable-next-line consistent-return
    tituloBoton() {
      if (this.origen === 'Manual') {
        return 'Ingreso de Luminaria'
      } if (this.origen === 'test') {
        return 'Test de ingreso'
      }
    },
    abrirModal() {
      this.modalLuminaria = true
    },
    closeModal() {
      // Este método se llama cuando se hace clic en "Cancelar" o se cierra el modal
      this.$bvModal.hide('modal-1')
    },
    agregarFoto() {
      this.fotosIngresadas.push('')
    },
    eliminarFoto(index) {
      if (this.fotosIngresadas.length > 1) {
        this.fotosIngresadas.splice(index, 1)
      }
    },
    imagenRecepcion(index, file) {
      this.$set(this.fotosIngresadas, index, file)
    },
  },
}
</script>

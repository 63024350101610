<template>
  <div>
    <b-button
      v-if="luminaria.estado !== 'DESPACHO' && luminaria.estado !== 'EN RUTA' && luminaria.estado !== 'DESPACHADA' && luminaria.estado !== 'INSTALACION' && luminaria.estado !== 'INSTALADA'"
      block
      variant="primary"
      @click="abrirModal"
    >
      {{ tituloModal() }}
    </b-button>
    <b-modal
      v-model="verModalPreDiagnostico"
      :title="tituloModal()"
      hide-footer
      no-close-on-backdrop
      @hide="limpiarModal"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="show"
          >
            <b-row v-if="luminaria.estado === 'PRUEBAS' || luminaria.estado === 'GARANTIA'">
              <b-col cols="12">
                <h5 class="text-primary">
                  Listado de Pruebas
                </h5>
                <b-table
                  v-model="pruebasSeleccionadas"
                  :items="pruebasLampara"
                  :fields="camposTabla"
                >
                  <template v-slot:cell(nombre)="{ item }">
                    <h5 class="align-text-top text-uppercase">
                      {{ item.nombre }}
                    </h5>
                  </template>
                  <template v-slot:cell(check)="{ item }">
                    <b-form-checkbox
                      v-model="item.checkboxEstado"
                      :disabled="checkboxHabilitado"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <h5 class="text-primary">
                  ¿Pruebas Exitosas?
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="¿Pruebas Exitosas?"
                    rules="required"
                  >
                    <v-select
                      v-model="destinoLuminaria"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="destinosLaboratorio"
                      @input="deshabilitarCheckbox"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios Pruebas"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentarios"
                      class="qr-textarea"
                      no-resize
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="luminaria.estado === 'PRUEBAS' || luminaria.estado === 'GARANTIA'">
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto de Luminaria</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosTracking"
                      :key="index"
                    >
                      <td style="width: 70%">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenTracking(index, $event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Guardar Diagnóstico
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormTextarea, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import vSelect from 'vue-select'
import { saveImagen } from '@/utils/imagenes'
import { getUtilsLaboratorio, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Imagen,
    BCol,
    BRow,
    BForm,
    BTable,
    BButton,
    BOverlay,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    luminaria: {
      type: Object,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    pruebasLampara: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      show: false,
      showOverlay: false,
      checkboxEstado: false,
      verModalPreDiagnostico: false,
      destinoLuminaria: '',
      pruebas: '',
      fotosTracking: [''],
      grupos: [],
      pruebasSeleccionadas: [],
      checkboxHabilitado: false,
      inspecciones: [],
      comentarios: null,
      comentariosGarantia: null,
      destinosLaboratorio: [],
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    this.pruebasLampara.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      p.selectedValue = false
    })
    this.destinosLaboratorio = getUtilsLaboratorio(1)
    this.inspecciones = getUtilsLaboratorio(2)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarPreDiagnostico()
          }
        })
    },
    async guardarPreDiagnostico() {
      try {
        this.show = true
        const datosUsuario = usuario => ({
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: `${usuario.email}`,
          telefono: '',
        })

        const body = { ...this.luminaria }

        // eslint-disable-next-line no-unused-vars
        let estado = ''

        if (this.luminaria.estado === 'PRUEBAS') {
          estado = 'PRUEBAS'
          body.estado = this.luminaria.estado === 'PRUEBAS' && this.destinoLuminaria.id === 1 ? 'DESPACHO' : 'GARANTIA'
          body.tracking.push({
            estado: 'PRUEBAS',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
          })
          body.tracking.push({
            estado: this.luminaria.estado === 'PRUEBAS' && this.destinoLuminaria.id === 1 ? 'DESPACHO' : 'GARANTIA',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
          })
          body.seguimiento.push({
            estado: 'PRUEBAS',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
            pruebas: this.pruebasSeleccionadas,
            comentarios: this.comentarios,
          })
        } else if (this.luminaria.estado === 'GARANTIA') {
          estado = 'GARANTIA'
          body.estado = 'DESPACHO'
          body.tracking.push({
            estado: 'GARANTIA',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
          })
          body.tracking.push({
            estado: 'DESPACHO',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
          })
          body.seguimiento.push({
            estado: 'GARANTIA',
            fecha: new Date(),
            usuario: datosUsuario(this.usuario),
            pruebas: this.pruebasSeleccionadas,
            comentarios: this.comentarios,
          })
        }

        const urlBucket = `luminaria/${body.id}`
        const isMobile = window.screen.width <= 760

        // eslint-disable-next-line no-restricted-syntax
        for (const foto of this.fotosTracking) {
          if (foto !== '') {
          // eslint-disable-next-line no-await-in-loop
            const imagen = await saveImagen(urlBucket, this.localization, foto, estado, '', this.luminaria.usuarioCreacion, isMobile)
            if (imagen) {
              body.imagenes.push(imagen)
            }
          }
        }
        await updateCreateLuminariaLab(body, 1)

        mensajeInformativo('Guardada', 'Datos almacenados correctamente!')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Datos almacenados correctamente!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Datos almacenados correctamente!',
          },
        })
        this.$emit('cerrar-detalle')
        this.limpiarModal()
        this.verModalPreDiagnostico = false
      } catch (error) {
        console.log(error)
        mensajeError('Se produjo un error al actualizar la luminaria.')
      } finally {
        this.show = false
      }
    },
    limpiarModal() {
      this.pruebasSeleccionadas = []
      this.pruebasLampara.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.checkboxEstado = false
      })
      this.destinoLuminaria = null
      this.comentarios = null
      this.fotosTracking = ['']
    },
    abrirModal() {
      this.verModalPreDiagnostico = true
    },
    // eslint-disable-next-line consistent-return
    tituloModal() {
      if (this.luminaria && this.luminaria.estado === 'INGRESADA') {
        return 'Confirmación de Datos'
        // eslint-disable-next-line no-mixed-operators
      } if (this.luminaria && this.luminaria.estado === 'PRUEBAS') {
        return 'Ingreso de pruebas'
      } if (this.luminaria && this.luminaria.estado === 'GARANTIA') {
        return 'Reparación de garantía'
      } if (this.luminaria && this.luminaria.estado === 'DESPACHO') {
        return 'Despachar Luminaria'
      }
    },
    deshabilitarCheckbox() {
      if (this.destinoLuminaria.id === 2) {
        this.pruebasSeleccionadas = []
        this.checkboxHabilitado = true
        this.pruebasLampara.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.checkboxEstado = false
          // eslint-disable-next-line no-param-reassign
          item.selectedValue = false
        })
      } else {
        this.checkboxHabilitado = false
      }
    },
    agregarFoto() {
      this.fotosTracking.push('')
    },
    eliminarFoto(index) {
      if (this.fotosTracking.length > 1) {
        this.fotosTracking.splice(index, 1)
      }
    },
    imagenTracking(index, file) {
      this.$set(this.fotosTracking, index, file)
    },
  },
}
</script>

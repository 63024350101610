var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticStyle:{"margin-top":"23px"},attrs:{"block":"","variant":"primary"},on:{"click":_vm.abrirModal}},[_vm._v(" "+_vm._s(_vm.tituloBoton())+" ")])],1)],1),_c('b-modal',{attrs:{"title":"Formulario de Recepción de Luminaria","no-close-on-backdrop":true,"no-close-on-esc":true,"ok-title":"Guardar Recepción","size":"lg","hide-footer":""},on:{"hidden":_vm.limpiarModal},model:{value:(_vm.modalLuminaria),callback:function ($$v) {_vm.modalLuminaria=$$v},expression:"modalLuminaria"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.show}},[_c('b-row',[(_vm.origenEmpresa === 'APMIX')?_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Tipo ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"qr-textarea",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.luminaria.tipo),callback:function ($$v) {_vm.$set(_vm.luminaria, "tipo", $$v)},expression:"luminaria.tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2426146182)})],1)],1):_c('b-col',{attrs:{"cols":"12","sm":_vm.origenEmpresa !== 'APMIX' ? 6 : 4}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Clase de Lámpara ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Clase de Lámpara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.modelos},model:{value:(_vm.luminaria.modeloLampara),callback:function ($$v) {_vm.$set(_vm.luminaria, "modeloLampara", $$v)},expression:"luminaria.modeloLampara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":_vm.origenEmpresa !== 'APMIX' ? 6 : 8}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Tipo Lámpara ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Tipo Lámpara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.tiposLampara},model:{value:(_vm.luminaria.tipoLampara),callback:function ($$v) {_vm.$set(_vm.luminaria, "tipoLampara", $$v)},expression:"luminaria.tipoLampara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":_vm.origenEmpresa !== 'APMIX' ? 6 : 4}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Serie ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Serie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"qr-textarea",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.luminaria.serie),callback:function ($$v) {_vm.$set(_vm.luminaria, "serie", $$v)},expression:"luminaria.serie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":_vm.origenEmpresa !== 'APMIX' ? 6 : 4}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Potencia ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Potencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"label":"nombre","value":"nombre","options":_vm.potencias},model:{value:(_vm.luminaria.potencia),callback:function ($$v) {_vm.$set(_vm.luminaria, "potencia", $$v)},expression:"luminaria.potencia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.origenEmpresa === 'APMIX')?_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Lote ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Lote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"qr-textarea",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.luminaria.lote),callback:function ($$v) {_vm.$set(_vm.luminaria, "lote", $$v)},expression:"luminaria.lote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,921973782)})],1)],1):_vm._e(),(_vm.origen === 'Manual')?_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Comentarios ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Comentarios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"qr-textarea",attrs:{"no-resize":"","state":errors.length > 0 ? false:null},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2166050505)})],1)],1):_vm._e()],1),_c('hr'),(_vm.origen !== 'test')?_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" Fotografía de Recepción de Luminaria ")])]),_c('b-col',{staticStyle:{"margin-top":"-10px"},attrs:{"cols":"5"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.agregarFoto($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Agregar Foto")])],1)],1),_c('b-col',{staticStyle:{"justify-content":"center","display":"flex"},attrs:{"cols":"12"}},[_c('b-form-group',[_c('table',_vm._l((_vm.fotosIngresadas),function(foto,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"280px"}},[_c('Imagen',{key:'recepcion-' + index,attrs:{"leyenda":'recepcion-' + index},on:{"cargar-file":function($event){return _vm.imagenRecepcion(index, $event)}}})],1),_c('td',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.eliminarFoto()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)])}),0)])],1)],1):_vm._e(),_c('hr'),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Guardar Recepción ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"gradient-primary"},on:{"click":_vm.abrirDetalle}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}})],1),_c('b-modal',{attrs:{"ok-only":"","ok-title":"Cerrar","modal-class":"modal-primary","centered":"","size":"lg","title":"Detalle del Seguimiento"},model:{value:(_vm.verDetalleReparaciones),callback:function ($$v) {_vm.verDetalleReparaciones=$$v},expression:"verDetalleReparaciones"}},[(_vm.datosSeleccionados && _vm.datosSeleccionados.destinoLuminaria && _vm.datosSeleccionados.destinoLuminaria.id === '1')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('h5',{staticClass:"text-primary"},[_vm._v(" Destino de Luminaria ")]),_c('b-form-input',{staticClass:"text-center",attrs:{"value":_vm.datosSeleccionados.destinoLuminaria.nombre,"disabled":""}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('center',[_c('h5',{staticClass:"text-primary"},[_vm._v(" Lista de Reparaciones de Laboratorio ")]),_c('v-select',{attrs:{"label":"nombre","multiple":""},model:{value:(_vm.datosSeleccionados.reparaciones),callback:function ($$v) {_vm.$set(_vm.datosSeleccionados, "reparaciones", $$v)},expression:"datosSeleccionados.reparaciones"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('center',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Listado de Pruebas de Luminaria ")]),_c('b-table',{attrs:{"items":_vm.datosSeleccionados.pruebas,"fields":_vm.camposTabla},scopedSlots:_vm._u([{key:"cell(nombre)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"align-text-top text-uppercase"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"disabled":""},model:{value:(item.pruebasSeleccionadas),callback:function ($$v) {_vm.$set(item, "pruebasSeleccionadas", $$v)},expression:"item.pruebasSeleccionadas"}})]}}],null,false,51206663)})],1)],1)],1):_vm._e(),(_vm.datosSeleccionados && _vm.datosSeleccionados.destinoLuminaria && _vm.datosSeleccionados.destinoLuminaria.id === '2')?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h5',{staticClass:"text-primary"},[_vm._v(" Grupo al que fue despachada ")]),_c('b-form-input',{staticClass:"text-center",attrs:{"disabled":""},model:{value:(_vm.datosSeleccionados.grupo.nombre),callback:function ($$v) {_vm.$set(_vm.datosSeleccionados.grupo, "nombre", $$v)},expression:"datosSeleccionados.grupo.nombre"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h5',{staticClass:"text-primary"},[_vm._v(" Destino de Luminaria ")]),_c('b-form-input',{staticClass:"text-center",attrs:{"value":_vm.datosSeleccionados.destinoLuminaria.nombre,"disabled":""}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('center',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Listado de Pruebas de Luminaria ")]),_c('b-table',{attrs:{"items":_vm.datosSeleccionados.pruebas,"fields":_vm.camposTabla},scopedSlots:_vm._u([{key:"cell(nombre)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"align-text-top text-uppercase"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"disabled":""},model:{value:(item.pruebasSeleccionadas),callback:function ($$v) {_vm.$set(item, "pruebasSeleccionadas", $$v)},expression:"item.pruebasSeleccionadas"}})]}}],null,false,51206663)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" Lista de Pre diagnósticos ")]),_c('b-table',{attrs:{"items":_vm.datosSeleccionados.preDiagnosticos,"fields":_vm.camposTabla},scopedSlots:_vm._u([{key:"cell(nombre)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"align-text-top text-uppercase"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"disabled":""},model:{value:(item.fallasSeleccionadas),callback:function ($$v) {_vm.$set(item, "fallasSeleccionadas", $$v)},expression:"item.fallasSeleccionadas"}})]}}],null,false,350610640)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('h6',{staticClass:"text-primary"},[_vm._v(" Fotografía ")]),_c('center',[_c('b-table',{attrs:{"items":_vm.datosTablaFoto,"fields":_vm.camposTablaFoto},scopedSlots:_vm._u([{key:"cell(nombre)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"align-text-top text-uppercase"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"cell(foto)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.mostrarDetalleFoto(item)}}},[_c('feather-icon',{attrs:{"icon":"CameraIcon"}})],1)]}}])})],1)],1)],1)],1),_c('div',[_c('b-modal',{attrs:{"modal-class":"modal-primary","centered":"","size":"lg","title":"Detalle del Seguimiento","ok-title":"Cerrar","ok-only":""},model:{value:(_vm.mostrarModalFoto),callback:function ($$v) {_vm.mostrarModalFoto=$$v},expression:"mostrarModalFoto"}},[_c('div',[_c('center',[_c('b-img',{attrs:{"src":_vm.urlImagen,"fluid":"","alt":"Imagen Cargada')"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
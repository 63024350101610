<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="gradient-primary"
      @click="abrirDetalle"
    >
      <feather-icon icon="ListIcon" />
    </b-button>
    <b-modal
      v-model="verDetalleReparaciones"
      ok-only
      ok-title="Cerrar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle del Seguimiento"
    >
      <b-row v-if="datosSeleccionados && datosSeleccionados.destinoLuminaria && datosSeleccionados.destinoLuminaria.id === '1'">
        <b-col
          cols="12"
        >
          <b-form-group>
            <h5 class="text-primary">
              Destino de Luminaria
            </h5>
            <b-form-input
              :value="datosSeleccionados.destinoLuminaria.nombre"
              class="text-center"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group>
            <center>
              <h5 class="text-primary">
                Lista de Reparaciones de Laboratorio
              </h5>
              <v-select
                v-model="datosSeleccionados.reparaciones"
                label="nombre"
                multiple
              />
            </center>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <center>
            <h6 class="text-primary">
              Listado de Pruebas de Luminaria
            </h6>
            <b-table
              :items="datosSeleccionados.pruebas"
              :fields="camposTabla"
            >
              <template v-slot:cell(nombre)="{ item }">
                <h5 class="align-text-top text-uppercase">
                  {{ item.nombre }}
                </h5>
              </template>
              <template v-slot:cell(check)="{ item }">
                <b-form-checkbox
                  v-model="item.pruebasSeleccionadas"
                  disabled
                />
              </template>
            </b-table>
          </center>
        </b-col>
      </b-row>
      <b-row v-if="datosSeleccionados && datosSeleccionados.destinoLuminaria && datosSeleccionados.destinoLuminaria.id === '2'">
        <b-col
          cols="6"
        >
          <b-form-group>
            <h5 class="text-primary">
              Grupo al que fue despachada
            </h5>
            <b-form-input
              v-model="datosSeleccionados.grupo.nombre"
              class="text-center"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group>
            <h5 class="text-primary">
              Destino de Luminaria
            </h5>
            <b-form-input
              :value="datosSeleccionados.destinoLuminaria.nombre"
              class="text-center"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <center>
            <h6 class="text-primary">
              Listado de Pruebas de Luminaria
            </h6>
            <b-table
              :items="datosSeleccionados.pruebas"
              :fields="camposTabla"
            >
              <template v-slot:cell(nombre)="{ item }">
                <h5 class="align-text-top text-uppercase">
                  {{ item.nombre }}
                </h5>
              </template>
              <template v-slot:cell(check)="{ item }">
                <b-form-checkbox
                  v-model="item.pruebasSeleccionadas"
                  disabled
                />
              </template>
            </b-table>
          </center>
        </b-col>
        <b-col
          cols="6"
        >
          <h6 class="text-primary">
            Lista de Pre diagnósticos
          </h6>
          <b-table
            :items="datosSeleccionados.preDiagnosticos"
            :fields="camposTabla"
          >
            <template v-slot:cell(nombre)="{ item }">
              <h5 class="align-text-top text-uppercase">
                {{ item.nombre }}
              </h5>
            </template>
            <template v-slot:cell(check)="{ item }">
              <b-form-checkbox
                v-model="item.fallasSeleccionadas"
                disabled
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <br>
          <h6 class="text-primary">
            Fotografía
          </h6>
          <center>
            <b-table
              :items="datosTablaFoto"
              :fields="camposTablaFoto"
            >
              <template v-slot:cell(nombre)="{ item }">
                <h5 class="align-text-top text-uppercase">
                  {{ item.nombre }}
                </h5>
              </template>
              <template v-slot:cell(foto)="{ item }">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  @click="mostrarDetalleFoto(item)"
                >
                  <feather-icon icon="CameraIcon" />
                </b-button>
              </template>
            </b-table>
          </center>
        </b-col>
      </b-row>
    </b-modal>
    <div>
      <b-modal
        v-model="mostrarModalFoto"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle del Seguimiento"
        ok-title="Cerrar"
        ok-only
      >
        <div>
          <center>
            <b-img
              :src="urlImagen"
              fluid
              alt="Imagen Cargada')"
            />
          </center>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BImg, BCol, BButton, VBModal, BFormInput, BFormGroup, BFormTextarea, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BTable,
    BButton,
    vSelect,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    datosSeleccionados: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      urlImagen: '',
      verDetalleReparaciones: false,
      mostrarModalFoto: false,
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      datosTablaFoto: [
        this.datosSeleccionados.foto,
      ],
      camposTablaFoto: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'foto', label: 'Fotografía' },
      ],
    }
  },
  computed: {
    destinoLuminariaActual() {
      if (this.datosSeleccionados && this.datosSeleccionados.destinoLuminaria) {
        const objetoEncontrado = this.datosSeleccionados

        if (objetoEncontrado) {
          return objetoEncontrado.destinoLuminaria.destinoLuminaria.nombre
        }
      }

      return 'No definido'
    },
  },
  methods: {
    abrirDetalle() {
      this.verDetalleReparaciones = true
    },
    mostrarDetalleFoto(item) {
      this.mostrarModalFoto = true
      this.urlImagen = item.url
    },
  },
}
</script>
<style scoped>
.v-select {
  pointer-events: none;
  background-color: #f0f0f0;
  color: #999;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

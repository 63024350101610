<template>
  <div>
    <b-row>
      <b-col
        :cols="origen !== 'APMIX' ? 6 : 4"
      >
        <h6 class="text-primary">
          Tipo
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.tipo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        :cols="origen !== 'APMIX' ? 6 : 8"
      >
        <h6 class="text-primary">
          Tipo Lámpara
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.tipoLampara.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        :cols="origen !== 'APMIX' ? 6 : 4"
      >
        <h6 class="text-primary">
          Serie
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="luminaria.serie"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        :cols="origen !== 'APMIX' ? 6 : 4"
      >
        <h6 class="text-primary">
          Potencia
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="luminaria.potencia.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="origen === 'APMIX'"
        cols="4"
      >
        <h6 class="text-primary">
          Lote
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="luminaria.lote"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="luminaria.seguimiento.length !== 0 && luminaria.estado !== 'REPARACION'"
        cols="12"
      >
        <cambio-estados-luminarias-lab
          :luminaria="luminaria"
          :tipos-lampara="tiposLampara"
          :potencias="potencias"
          :pruebas-lampara="pruebasLampara"
          @cerrar-detalle="actualizarTabla"
        />
      </b-col>
      <b-col
        v-if="luminaria.usuarioCreacion.nombre === 'ADMINISTRADOR' && luminaria.estado === 'INGRESADA'"
        cols="12"
      >
        <confirmacion-datos
          :tipos-lampara="tiposLampara"
          :potencias="potencias"
          :luminaria="luminaria"
          @cerrar-ingreso="actualizarTabla"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Seguimiento
        </h6>
        <vue-good-table
          :columns="columns"
          :rows="luminaria.seguimiento"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <div v-if="props.row.estado === 'Detalles de la Reparación'">
                <detalle-reparacion-luminaria :datos-seleccionados="props.row" />
              </div>
              <div v-else>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  @click="mostrarDetalle(props)"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </div>
            </span>
            <span v-else-if="props.column.field == 'estado'">
              <span>{{ estadoSeguimiento(props.row.estado) }}</span>
            </span>
            <span v-else-if="props.column.field == 'fecha'">
              <span>{{ formatFecha(props.row.fecha) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
      <b-modal
        v-model="verDialogoDocumentoItem"
        hide-footer
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle del Seguimiento"
      >
        <b-row v-if="verDialogoDocumentoItem">
          <b-col
            v-if="seguimientoSeleccionado.usuarioAsignado && usuario.company.name !== 'APMIX'"
            cols="6"
          >
            <h6 class="text-primary">
              Grupo
            </h6>
            <b-form-group>
              <b-form-input
                class="text-center"
                :value="seguimientoSeleccionado && seguimientoSeleccionado.grupo && seguimientoSeleccionado.grupo.nombre"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="seguimientoSeleccionado.mejorasRealizadas && usuario.company.name !== 'APMIX'"
            cols="6"
          >
            <h6 class="text-primary">
              Grupo
            </h6>
            <b-form-group>
              <b-form-input
                class="text-center"
                :value="seguimientoSeleccionado && seguimientoSeleccionado.mejorasRealizadas && seguimientoSeleccionado.mejorasRealizadas.nombre"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="seguimientoSeleccionado.usuarioAsignado && usuario.company.name === 'APMIX'"
            cols="5"
          >
            <h6 class="text-primary">
              Técnico Asignado
            </h6>
            <b-form-group>
              <b-form-input
                :value="seguimientoSeleccionado && seguimientoSeleccionado.usuarioAsignado && seguimientoSeleccionado.usuarioAsignado.nombre"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="mostrarSeguimiento(seguimientoSeleccionado.estado)"
            cols="12"
          >
            <h6 class="text-primary">
              Lista de Pruebas Realizadas
            </h6>
            <b-table
              :items="seguimientoSeleccionado.pruebas"
              :fields="camposTabla"
            >
              <template v-slot:cell(nombre)="{ item }">
                <h5 class="align-text-top text-uppercase">
                  {{ item.nombre }}
                </h5>
              </template>
              <template v-slot:cell(check)="{ item }">
                <b-form-checkbox
                  v-model="item.checkboxEstado"
                  disabled
                />
              </template>
            </b-table>
          </b-col>
          <b-col
            v-if="seguimientoSeleccionado.comentarios"
            cols="12"
          >
            <h6 class="text-primary">
              Comentarios
            </h6>
            <b-form-group>
              <b-form-textarea
                :value="seguimientoSeleccionado && seguimientoSeleccionado.comentarios"
                disabled
                no-resize
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
          >
            <h6 class="text-primary">
              Fotografías
            </h6>
            <vue-good-table
              :columns="columnsDetalle"
              :rows="imagenesSeguimiento"
              style-class="vgt-table condensed"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'detalleFoto'">
                  <ImagenShowReemplazo
                    :posicion-imagen="getIndexImage(props)"
                    :ticket="luminaria"
                    :clave="'imagenes'"
                    :origen="origenes.luminarias"
                  />
                </span>
                <span v-else-if="props.column.field === 'fechaHora'">
                  <span>{{ formatFecha(props.row.fechaHora) }}</span>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormInput, BFormGroup, VBModal, BButton, BTable, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import { calcularFecha } from '@/utils/fechas'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { origenes } from '@/utils/origenesImagen'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import CambioEstadosLuminariasLab from '@/components/laboratorio/luminarias/cambioEstadosLuminariasLab.vue'
import { getUtilsLaboratorio } from '@/utils/laboratorio/luminarias'
import ConfirmacionDatos from '@/components/laboratorio/luminarias/confirmacionDatos.vue'
import DetalleReparacionLuminaria from '@/components/laboratorio/luminarias/detalle-reparacion-luminaria.vue'

export default {
  components: {
    DetalleReparacionLuminaria,
    ConfirmacionDatos,
    CambioEstadosLuminariasLab,
    ImagenShowReemplazo,
    BCol,
    BRow,
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    luminaria: {
      type: Object,
      required: true,
    },
    potencias: {
      type: Array,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    pruebasLampara: {
      type: Array,
      required: true,
    },
    origen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      origenes,
      urlImagen: null,
      seguimientoSeleccionado: null,
      imagenesSeguimiento: [],
      posicionImagen: 0,
      mostrarColDetalle: true,
      verDialogoDocumentoItem: false,
      objetoTicket: this.luminaria,
      pruebasSeleccionadas: [],
      estados: '',
      columns: [
        {
          label: 'Estado',
          field: 'estado',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fecha',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      column: [
        {
          label: 'Tipo',
          field: 'tipo.nombre',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Correlativo',
          field: 'correlativo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      columnsDetalle: [
        {
          label: 'Fecha / Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalleFoto',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.estados = getUtilsLaboratorio(3)
  },
  methods: {
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    mostrarSeguimiento(estado) {
      return estado === this.estados.PRUEBAS.value || estado === this.estados.GARANTIA.value
    },
    getImagesFromSeguimiento() {
      if (this.objetoDeSeguimientoActual && this.objetoDeSeguimientoActual.imagenes) {
        return this.objetoDeSeguimientoActual.imagenes
      }
      return []
    },
    getIndexImage(item) {
      const imagen = item.row
      return this.luminaria.imagenes.findIndex(obj => obj.nombre === imagen.nombre && obj.tipo === imagen.tipo && obj.urlInternal === imagen.urlInternal)
    },
    mostrarDetalle(params) {
      this.seguimientoSeleccionado = params.row
      this.imagenesSeguimiento = this.luminaria.imagenes.filter(item => item.tipo === params.row.estado)
      this.verDialogoDocumentoItem = true

      this.mostrarColDetalle = this.imagenesSeguimiento.length !== 0
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    actualizarTabla() {
      this.$emit('actualizar')
    },
  },
}
</script>

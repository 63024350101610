<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          block
          variant="primary"
          @click="abrirModal"
        >
          Confirmación de Datos
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalLuminaria"
      title="Confirmación de Datos"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Guardar Recepción"
      size="lg"
      hide-footer
      @hide="cerrarModalConfirmacion"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="show"
          >
            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Tipo
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="tipo"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <h5 class="text-primary">
                  Tipo Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="tipoLampara"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="tiposLampara"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Serie
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Serie"
                    rules="required"
                  >
                    <b-form-input
                      v-model="serie"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Potencia
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Potencia"
                    rules="required"
                  >
                    <v-select
                      v-model="potencia"
                      :state="errors.length > 0 ? false : null"
                      label="nombre"
                      class="qr-textarea"
                      value="nombre"
                      :options="potencias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Lote
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Lote"
                    rules="required"
                  >
                    <b-form-input
                      v-model="lote"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentario"
                      class="qr-textarea"
                      :state="errors.length > 0 ? false:null"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="7">
                <h6 class="text-primary">
                  Fotografía de Recepción de Luminaria
                </h6>
              </b-col>
              <b-col
                cols="5"
                style="margin-top: -10px"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto</span>
                </b-button>
              </b-col>
              <b-col
                style="justify-content: center; display: flex"
                cols="12"
              >
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosIngresadas"
                      :key="index"
                    >
                      <td style="width: 280px">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenRecepcion(index, $event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validarDatos"
                >
                  Guardar Recepción
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
    <div>
      <b-modal
        v-model="showModalQr"
        hide-footer
        modal-class="modal-primary"
        centered
        title="Escaneo de QR"
        @hide="cerrarModalConfirmacion"
      >
        <b-overlay
          :show="loading"
        >
          <b-row style="display: flex; justify-content: center">
            <b-row>
              <b-col
                v-if="!textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <h1 class="text-primary text-uppercase">
                  ¡Escanea el código QR!
                </h1>
              </b-col>
              <b-col
                v-if="!textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <b-img
                  :src="require('/public/qr-code-icon.gif')"
                  alt="Escaner QR"
                  style="width: 180px; height: 180px; border-radius: 15px; padding: 5px; box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);"
                />
              </b-col>
              <b-col
                v-if="textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <h1 class="text-primary text-uppercase">
                  ¡Los datos coinciden!
                </h1>
              </b-col>
              <b-col
                v-if="textoEscaneado"
                style="display: flex; justify-content: center"
                cols="12"
              >
                <b-img
                  :src="require('/public/check-green.gif')"
                  alt="Escaner QR"
                  style="width: 180px; height: 180px; border-radius: 15px; padding: 5px; box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);"
                />
              </b-col>
            </b-row>
            <b-col cols="12">
              <input
                ref="inputQR"
                v-model="textoEscaneado"
                class="qr-textarea"
                type="text"
                style="position: absolute; top: -9999px"
                @change="extraerCodigos"
              >
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { saveImagen } from '@/utils/imagenes'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { findLuminariasLab, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Imagen,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BCol,
    BImg,
    BRow,
    BForm,
    vSelect,
    BOverlay,
    BFormInput,
    BFormTextarea,
  },
  props: {
    potencias: {
      type: Array,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    luminaria: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      show: false,
      loading: false,
      showModalQr: false,
      modalLuminaria: false,
      tipo: '',
      serie: '',
      potencia: '',
      lote: '',
      tipoLampara: '',
      comentario: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
      fotosIngresadas: [''],
      textoEscaneado: null,
      serieExtraida: null,
      tipoExtraido: null,
      timeoutId: null,
    }
  },
  async created() {
    this.tipo = this.luminaria.tipo
    this.serie = this.luminaria.serie
    this.potencia = this.luminaria.potencia
    this.tipoLampara = this.luminaria.tipoLampara
    this.lote = this.luminaria.lote
  },
  methods: {
    limpiarCampos() {
      this.textoEscaneado = null
      this.tipoExtraido = null
      this.serieExtraida = null
    },
    validarDatos() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.abrirModalQR()
          }
        })
    },
    abrirModalQR() {
      this.showModalQr = true
      this.timeoutId = setInterval(() => {
        this.$nextTick(() => {
          this.$refs.inputQR.focus()
        })
      }, 500)
    },
    async validarLuminaria() {
      try {
        const luminariaQR = await this.buscarLuminaria()
        if (luminariaQR && luminariaQR.length > 0) {
          const luminariaEncontrada = luminariaQR[0]
          if (String(luminariaEncontrada.serie) === String(this.serie) && String(luminariaEncontrada.tipo) === String(this.tipo) && String(luminariaEncontrada.id) === String(this.luminaria.id)) {
            this.validationForm()
          } else {
            mensajeError('Los datos no coinciden')
          }
        } else {
          mensajeError('No se encontró ninguna luminaria con la serie y el tipo especificados')
        }
      } catch (error) {
        console.log(error)
      } finally {
        clearInterval(this.timeoutId)
        this.loading = false
        this.showModalQr = false
        this.limpiarCampos()
      }
    },
    extraerCodigos() {
      const removerPrimerCodigo = /P-N[Ññ]\s*([^\s]+)/
      const removerSegundoCodigo = /S-N[Ññ]\s*([^\s]+)/

      const matchPrimerCodigo = this.textoEscaneado.match(removerPrimerCodigo)
      const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

      if (matchPrimerCodigo && matchSegundoCodigo) {
        // eslint-disable-next-line prefer-destructuring
        this.tipoExtraido = matchPrimerCodigo[1]
        // eslint-disable-next-line prefer-destructuring
        this.serieExtraida = matchSegundoCodigo[1]

        const filtro = {
          where: {
            serie: matchSegundoCodigo[1],
            tipo: matchPrimerCodigo[1],
          },
        }

        this.buscarLuminaria(filtro).then(() => {
          this.validarLuminaria()
        })
      } else {
        mensajeError('Texto del escáner no válido')
        this.textoEscaneado = null
        this.tipoExtraido = null
        this.serieExtraida = null
      }
    },
    async buscarLuminaria(filtro = null) {
      try {
        this.loading = true
        const filter = filtro || {
          where: {
            companyId: this.usuario.company.id,
            serie: this.serieExtraida,
            tipo: this.tipoExtraido,
          },
        }
        return await findLuminariasLab(filter)
      } catch (error) {
        console.log('Error al buscar', error)
        this.loading = false
        return null
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarRecepcion()
            this.$bvModal.hide('modal-1')
          }
        })
    },
    async guardarRecepcion() {
      try {
        this.show = true

        const datosUsuario = usuario => ({
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: `${usuario.email}`,
          telefono: '',
        })

        this.luminaria.usuarioCreacion = datosUsuario(this.usuario)
        const camposLuminaria = {
          id: this.luminaria.id,
          tipo: this.luminaria.tipo,
          serie: this.luminaria.serie,
          potencia: this.luminaria.potencia,
          tipoLampara: this.luminaria.tipoLampara,
          lote: this.luminaria.lote,
          estado: this.luminaria.estado,
        }

        if (this.luminaria.estado === 'INGRESADA') {
          this.luminaria.tipo = this.tipo
          this.luminaria.serie = this.serie
          this.luminaria.potencia = this.potencia
          this.luminaria.tipoLampara = this.tipoLampara
          this.luminaria.lote = this.lote
          this.luminaria.estado = 'PRUEBAS'
          this.luminaria.seguimiento.push({
            usuario: datosUsuario(this.usuario),
            estado: 'INGRESADA',
            fecha: new Date(),
            comentarios: this.comentario,
            luminariaInicial: camposLuminaria,
          })
          this.luminaria.tracking.push({
            usuario: datosUsuario(this.usuario),
            estado: 'PRUEBAS',
            fecha: new Date(),
          })
        }

        const body = { ...this.luminaria }

        if (!body) {
          mensajeError('Inconvenientes almacenando la recepción de luminaria!, intente nuevamente!')
          this.show = false
          return
        }

        const urlBucket = `luminaria/${body.id}`
        const isMobile = window.screen.width <= 760

        // eslint-disable-next-line no-restricted-syntax
        for (const foto of this.fotosIngresadas) {
          if (foto !== '') {
            // eslint-disable-next-line no-await-in-loop
            const imagen = await saveImagen(urlBucket, this.localization, foto, 'INGRESADA', '', this.luminaria.usuarioCreacion, isMobile)
            if (imagen) {
              body.imagenes.push(imagen)
            }
          }
        }
        await updateCreateLuminariaLab(body, 1)
        clearInterval(this.timeoutId)

        mensajeInformativo('Guardada', 'Luminaria Almacenada Correctamente!')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Luminaria Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Luminaria almacenada exitosamente!',
          },
        })
        this.$emit('cerrar-ingreso')
        this.modalLuminaria = false
      } catch (error) {
        console.log(error)
        mensajeError('Se produjo un error al guardar la luminaria.')
      } finally {
        this.show = false
      }
    },
    abrirModal() {
      this.modalLuminaria = true
    },
    cerrarModalConfirmacion() {
      clearInterval(this.timeoutId)
    },
    agregarFoto() {
      this.fotosIngresadas.push('')
    },
    eliminarFoto(index) {
      if (this.fotosIngresadas.length > 1) {
        this.fotosIngresadas.splice(index, 1)
      }
    },
    imagenRecepcion(index, file) {
      this.$set(this.fotosIngresadas, index, file)
    },
  },
}

</script>
